import React, { useState } from 'react'
import { graphql, Link } from 'gatsby'
import { GatsbyImage } from "gatsby-plugin-image";

import Resume from "./../../images/online-resume.svg"

import "./OurTeam.css";


function OurTeam(props) {
    const data = props.data;

    return (
        <div className={`pt-16 pb-16 md:pb-16 ${data.title ? "lg:pt-40" : "md:pt-16"} `}>
            <div className="m-auto max-w-6xl text-center">
                {data.title && (
                    <>
                        <p className="section-name">{data.subtitle}</p>
                        <h2 style={{ color: "#031b4e" }} >
                            {data.title}
                        </h2>
                        <p className="m-auto mt-4" style={{ maxWidth: "500px" }}>{data.par}</p>
                    </>
                )}

                <div className="flex flex-wrap py-10">
                    {data.members && data.members.map((member, index) => {
                        if (member.node.teamMembersAcf.biographySlug) {
                            return (
                                <Link to={"/" + member.node.teamMembersAcf.biographySlug + "/"} key={`member-${index}`} className=" w-1/2 md:w-1/4 relative px-4">
                                    <div className="team-img">
                                        <GatsbyImage
                                            image={member.node.teamMembersAcf.memberImage.imageFile.childImageSharp.gatsbyImageData}
                                            alt={member.node.teamMembersAcf.memberImage.altText}
                                            className="h-full w-full m-auto" />
                                    </div>
                                    <div className="team-infobox main-color" style={{ padding: "18px 15px" }}>
                                        <div className="font-semibold w-full">
                                            {member.node.teamMembersAcf.fullName}
                                        </div>
                                        <div className="w-full jtitle">
                                            {member.node.teamMembersAcf.jobTitle}
                                        </div>
                                    </div>
                                    <div className="bio-inc">
                                        <img src={Resume} alt="resume" />
                                    </div>
                                </Link>
                            );
                        } else {
                            return (
                                <div key={`member-${index}`} className=" w-1/2 md:w-1/4 relative px-4">
                                    <div className="team-img">
                                        <GatsbyImage
                                            image={member.node.teamMembersAcf.memberImage.imageFile.childImageSharp.gatsbyImageData}
                                            alt={member.node.teamMembersAcf.memberImage.altText}
                                            className="h-full w-full m-auto" />
                                    </div>
                                    <div className="team-infobox main-color" style={{ padding: "18px 15px" }}>
                                        <div className="font-semibold w-full">
                                            {member.node.teamMembersAcf.fullName}
                                        </div>
                                        <div className="w-full jtitle">
                                            {member.node.teamMembersAcf.jobTitle}
                                        </div>
                                    </div>
                                </div>
                            );
                        }
                    })}
                </div>
                {(data.cta && data.lang == "EL") && (
                    <Link to={"/team"} className="tm-cta">{data.cta}</Link>
                )}

                {(data.cta && data.lang == "EN") && (
                    <Link to={"/en/team-plus"} className="tm-cta">{data.cta}</Link>
                )}

            </div>
        </div>
    );
}

export default OurTeam;

export const query = graphql`fragment OurTeamFragment on WPGraphQL_RootQueryToTeamMemberConnection {
  edges {
    node {
      teamMembersAcf {
        fullName
        jobTitle
        memberImage {
          sourceUrl
          altText
          imageFile {
            childImageSharp {
              gatsbyImageData(
                width: 300
                quality: 100
                placeholder: NONE
                layout: CONSTRAINED
              )
            }
          }
        }
        biographySlug
      }
    }
  }
}
`